import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  FULL_RANGE_OPTIONS,
  Page,
  PageBody,
  Spinner,
  Select,
} from '@studio/legacy-components';
import { selectExperience } from 'next/entities/experiences';
import { selectAccount } from 'next/entities/account';
import { replace as replacePage } from 'next/entities/page';
import {
  MOBILE_ANALYTICS,
  MOBILE_ANALYTICS_SEGMENT_FILTER,
  SURVEYS_RESPONSE,
  selectFeature,
} from 'next/entities/features';
import BoundedDateRangeDropdown from 'next/components/BoundedDateRangeDropdown';
import { Control, Controls, ControlSeparator } from 'next/components/Listing';
import MobileHeader from 'next/components/MobileHeader';
import { selectExperienceHistoricalLimit } from 'next/lib/selectors';
import { clear } from 'next/entities/analytics';
import TimestampRefresh from 'next/components/TimestampRefresh';
import { selectSegmentTargetingOptions } from 'next/lib/selectors-options';
import { RecentUsersNewProvider } from 'next/components/RecentUsers';
import { selectRule, selectRuleGoal } from 'next/entities/rules';
import NotPublished from 'next/components/NotPublished/NotPublished';
import SurveyResponses from 'next/components/SurveyResponses';
import useDateRangeQuery from 'next/hooks/use-date-range-query';
import AnalyticsHeaderActions from './AnalyticsHeaderActions';
import ActivityChart from './ActivityChart';
import ComingSoon from './ComingSoon';
import { Loading } from './styled';
import OverviewMetrics from './OverviewMetrics';
import StepBreakdown from './StepBreakdown';

export function Analytics({
  accountId,
  onLoad,
  loaded,
  id,
  createdAt,
  hasMobileAnalytics,
  hasSegmentFilter,
  limit,
  segmentsOptions = [],
  onRefresh,
  goalId,
  published,
  hasSurveys,
  rule,
}) {
  const [segmentId, setSegmentId] = useState(null);
  const [updated, setUpdated] = useState(Date.now());

  const handleRefresh = () => {
    onRefresh();
    setUpdated(Date.now());
  };

  const selected = segmentsOptions.find(option => option.value === segmentId);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const [{ range, start, end }, setDateRange] = useDateRangeQuery(
    FULL_RANGE_OPTIONS,
    {
      range: 7,
    }
  );

  if (!loaded) {
    return (
      <Loading>
        <Spinner aria-label="Loading" />
      </Loading>
    );
  }
  return (
    <Page>
      <MobileHeader
        id={id}
        currentPage="analytics"
        renderActions={() => {
          return (
            <AnalyticsHeaderActions
              id={id}
              accountId={accountId}
              showExport={hasMobileAnalytics}
              createdAt={createdAt}
            />
          );
        }}
      />
      <PageBody>
        {hasMobileAnalytics ? (
          published ? (
            <>
              <Controls>
                <Control>
                  <BoundedDateRangeDropdown
                    limit={limit}
                    onApply={setDateRange}
                    options={FULL_RANGE_OPTIONS}
                    value={{ range, start, end }}
                  />
                </Control>
                {hasSegmentFilter && (
                  <Control>
                    <Select
                      icon="users"
                      onChange={option => setSegmentId(option?.value ?? null)}
                      options={segmentsOptions}
                      placeholder="Select a segment..."
                      value={selected}
                      isClearable
                    />
                  </Control>
                )}
                <ControlSeparator />
                <Control>
                  <TimestampRefresh endTime={updated} onClick={handleRefresh} />
                </Control>
              </Controls>
              <ActivityChart
                startTime={start.getTime()}
                endTime={end.getTime()}
                id={id}
                segmentId={segmentId}
                updated={updated}
                goalId={goalId}
              />
              <OverviewMetrics
                id={id}
                endTime={end.getTime()}
                startTime={start.getTime()}
                segmentId={segmentId}
                goalId={goalId}
                updated={updated}
                accountId={accountId}
                rule={rule}
              />
              <StepBreakdown
                id={id}
                endTime={end.getTime()}
                startTime={start.getTime()}
                segmentId={segmentId}
                updated={updated}
              />
              {hasSurveys && (
                <SurveyResponses
                  flowId={id}
                  start={new Date(start).toISOString()}
                  end={new Date(end).toISOString()}
                />
              )}
              <RecentUsersNewProvider
                id={id}
                endTime={end.toISOString()}
                startTime={start.toISOString()}
                limit={10}
                type="Mobile Flow"
              />
              )
            </>
          ) : (
            <NotPublished
              title="Track Flow performance"
              description="View key performance metrics about this Flow including how your users' activity is impacting your goals."
              action={{
                link: `/mobile/flows/${id}/settings`,
                label: 'Publish Flow',
              }}
            />
          )
        ) : (
          <ComingSoon />
        )}
      </PageBody>
    </Page>
  );
}

Analytics.propTypes = {
  accountId: PropTypes.string,
  id: PropTypes.string,
  createdAt: PropTypes.number,
  loaded: PropTypes.bool,
  onLoad: PropTypes.func,
  hasMobileAnalytics: PropTypes.bool,
  hasSegmentFilter: PropTypes.bool,
  limit: PropTypes.number,
  segmentsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  onRefresh: PropTypes.func,
  goalId: PropTypes.string,
  rule: PropTypes.shape({
    goalConversionWindowDays: PropTypes.number,
  }),
  published: PropTypes.bool,
  hasSurveys: PropTypes.bool,
};

const mapStateToProps = (
  state,
  {
    match: {
      params: { experienceId },
    },
  }
) => {
  const { id: accountId } = selectAccount(state);
  const experience = selectExperience(state, experienceId);
  const rule = selectRule(state, experienceId);

  return {
    accountId,
    id: experienceId,
    createdAt: experience?.createdAt || 0,
    loaded: Boolean(experience && rule),
    hasMobileAnalytics: selectFeature(state, MOBILE_ANALYTICS),
    hasSegmentFilter: selectFeature(state, MOBILE_ANALYTICS_SEGMENT_FILTER),
    limit: selectExperienceHistoricalLimit(state),
    segmentsOptions: selectSegmentTargetingOptions(state),
    goalId: selectRuleGoal(state, experienceId),
    published: Boolean(experience?.publishedAt),
    hasSurveys: selectFeature(state, SURVEYS_RESPONSE),
    rule,
  };
};

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      path,
      params: { experienceId: id },
    },
  }
) => ({
  onLoad: () => dispatch(replacePage({ path, id })),
  onRefresh: () => dispatch(clear()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Analytics);
