import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Panel as UIPanel, H3, PanelHeader } from '@studio/legacy-components';

import EventSearchBar from 'components/Insights/Common/EventSearchBar';
import EventsTable from './EventsTableProvider';

export const Panel = ({ startTime, endTime, events }) => {
  const [searchTerm, setSearchTerm] = useState('');
  return (
    <UIPanel>
      <PanelHeader>
        <H3>Events</H3>
        <EventSearchBar placeholder="Search" onChange={setSearchTerm} />
      </PanelHeader>

      <EventsTable
        names={events.map(({ event }) => event)}
        stepIds={events.map(({ stepId }) => stepId)}
        startTime={startTime}
        endTime={endTime}
        searchTerm={searchTerm}
        contentType={events[0].source}
        contentId={events[0].id}
      />
    </UIPanel>
  );
};

Panel.propTypes = {
  source: PropTypes.string,
  startTime: PropTypes.number,
  endTime: PropTypes.number,
  events: PropTypes.arrayOf(
    PropTypes.shape({
      event: PropTypes.string,
      id: PropTypes.string,
      source: PropTypes.string,
      stepId: PropTypes.string,
    })
  ),
  segmentId: PropTypes.string,
  pageSize: PropTypes.number,
};

export default Panel;
