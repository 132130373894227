/* global CANNY_APP_ID */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { MainNavigation } from '@studio/navigation';
import { NavLink, useLocation } from 'react-router-dom';
import { selectUser, logout } from 'next/entities/user';
import { selectAccount } from 'next/entities/account';
import { selectAccounts } from 'next/entities/accounts';
import {
  LAUNCHPADS_V2,
  PINS,
  PINS_BETA,
  selectFeature,
  WORKFLOWS,
  PAGE_COLLECTIONS,
  OPM_NEW_NAV,
} from 'next/entities/features';
import { selectTrialStatus } from 'next/entities/trial-status';
import { selectSpoofing, start as startSpoofing } from 'next/entities/spoofing';
import useLaunchpad from 'next/hooks/use-launchpad';

const MainNavigationProvider = ({
  user,
  account,
  accountsCount,
  hasPins,
  hasLaunchpads,
  hasWorkflows,
  hasPageCollections,
  hasOPMNewNav,
  isSpoofing,
  onSpoof,
  onLogout,
  isInstalled,
  trialStatus,
  cannyAppId,
}) => {
  const navigationFeatureFlags = {
    hasPins,
    hasLaunchpads,
    hasWorkflows,
    hasPageCollections,
    hasOPMNewNav,
    // While dev-tools on studio-classic uses the flask button and modal, we should hide the devtools link in the nav menu
    hasDevTools: false,
  };
  const launchpadData = useLaunchpad(user?.id);
  const { pathname } = useLocation();

  return (
    <MainNavigation
      /* This is an inconsistency between GraphQL <> REST  */
      user={{ ...user, fullName: user.fullname }}
      account={account}
      accountsCount={accountsCount}
      onSpoof={onSpoof}
      onLogout={onLogout}
      isSpoofing={isSpoofing}
      isInstalled={isInstalled}
      NavLink={NavLink}
      featureFlags={navigationFeatureFlags}
      trialStatus={trialStatus}
      cannyAppId={cannyAppId}
      matchExactRoutes
      launchpadData={launchpadData}
      currentPath={pathname}
    />
  );
};

MainNavigationProvider.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    fullname: PropTypes.string,
    email: PropTypes.string,
    isAdmin: PropTypes.bool,
  }),
  account: PropTypes.shape({ name: PropTypes.string, id: PropTypes.string }),
  trialStatus: PropTypes.shape({
    expirationDate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    createdAt: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isExpired: PropTypes.bool,
    isWebInstalled: PropTypes.bool,
    lengthDays: PropTypes.number,
  }),
  accountsCount: PropTypes.number,
  hasPins: PropTypes.bool,
  hasLaunchpads: PropTypes.bool,
  hasWorkflows: PropTypes.bool,
  hasPageCollections: PropTypes.bool,
  hasOPMNewNav: PropTypes.bool,
  isSpoofing: PropTypes.bool,
  isInstalled: PropTypes.bool,
  onSpoof: PropTypes.func,
  onLogout: PropTypes.func,
  cannyAppId: PropTypes.string,
};

const mapStateToProps = state => {
  const accounts = selectAccounts(state);
  const accountsCount = Object.keys(accounts ?? {}).length;
  const trialStatus = selectTrialStatus(state);
  const { isWebInstalled, isMobileInstalled } = trialStatus;
  const isInstalled = Boolean(isWebInstalled) || Boolean(isMobileInstalled);

  return {
    user: selectUser(state),
    account: selectAccount(state),
    accountsCount,
    hasPins: selectFeature(state, PINS),
    hasPinsBeta: selectFeature(state, PINS_BETA),
    hasLaunchpads: selectFeature(state, LAUNCHPADS_V2),
    hasWorkflows: selectFeature(state, WORKFLOWS),
    hasPageCollections: selectFeature(state, PAGE_COLLECTIONS),
    hasOPMNewNav: selectFeature(state, OPM_NEW_NAV),
    isSpoofing: selectSpoofing(state),
    trialStatus,
    isInstalled,
    cannyAppId: CANNY_APP_ID,
  };
};

const mapDispatchToProps = {
  onSpoof: startSpoofing,
  onLogout: logout,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainNavigationProvider);
