/* eslint-disable @appcues/jsx-props-no-spreading */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import {
  UserIntent,
  UsageIntent,
  InviteTeamMembers,
} from 'next/pages/UserIntent';
import { FlowsListingPage, FlowSettingsPage } from 'next/pages/flows';
import {
  SettingsPage as MobileSettingsPage,
  AnalyticsPage as MobileAnalyticsPage,
} from 'next/pages/mobile';
import { PinsPage, SettingsPage, AnalyticsPage } from 'next/pages/pins';
import {
  BannersPage,
  BannerSettingsPage,
  BannerAnalyticsPage,
} from 'next/pages/banners';
import { LaunchpadSettingsPage, LaunchpadsPage } from 'next/pages/launchpad';
import { AccountsPage } from 'next/pages/accounts';
import {
  selectFeatures,
  PINS_BETA,
  PINS,
  MOBILE_FLOWS,
  NEXT_FLOW_SETTINGS,
  LAUNCHPADS_V2,
} from 'next/entities/features';
import TemporaryLayout from 'next/pages/TemporaryLayout';
import { SignupInfo } from 'next/pages/SignupInfo';
import { RedirectToStudio5 } from 'next/lib/platform';
import useListeners from './use-listeners';

/**
 * Creates a route that redirects the user to studio5.
 *
 * @param {string} path - Route path
 * @param {string} [feature] - Feature flag associated with the route
 * @returns {object} Route metadata object
 */
const createExternalRoute = (path, feature) => ({
  enabled: feature ? undefined : true, // defaults to true (enabled) if there's no feature flag associated
  feature,
  path,
  exact: true,
  render: () => {
    return (
      <TemporaryLayout>
        <RedirectToStudio5 />
      </TemporaryLayout>
    );
  },
});

/**
 * Shareable route registry
 *
 * NOTE: In the future, we can inline the parameters directly as props to
 *       <Route>, but since we need to create conditional passthrough routes in
 *       legacy studio as well, we need to define these routes in a way it can
 *       be consumed in multiple places
 *
 * FIXME: remove TemporaryLayout once we make the Nav independent from old studio
 */
export const routes = [
  createExternalRoute('/apps/:id'),
  createExternalRoute('/audience/accounts'),
  createExternalRoute('/audience/accounts/:id/overview'),
  createExternalRoute('/audience/accounts/:id/properties'),
  createExternalRoute('/audience/accounts/:id/users'),
  createExternalRoute('/audience/segments-v2'),
  createExternalRoute('/emails/:id'),
  createExternalRoute('/experiments'),
  createExternalRoute('/experiments/:id/settings'),
  createExternalRoute('/flows/performance'),
  createExternalRoute('/insights/pages'),
  createExternalRoute('/integrations/connections'),
  createExternalRoute('/integrations/webhooks'),
  createExternalRoute('/journeys'),
  createExternalRoute('/journeys/:id'),
  createExternalRoute('/journeys/preview'),
  createExternalRoute('/launchpads/:id/analytics'),
  createExternalRoute('/launchpads/:id/builder'),
  createExternalRoute('/launchpads/builder'),
  createExternalRoute('/push-notifications/:id'),
  createExternalRoute('/segments-v2/:id/edit'),
  createExternalRoute('/segments-v2/:id/view'),
  createExternalRoute('/settings/emails/senders'),
  createExternalRoute('/settings/emails/suppression-lists/unsubscribes'),
  createExternalRoute('/settings/emails/suppression-lists/bounces'),
  createExternalRoute('/settings/emails/suppression-lists/spams'),
  createExternalRoute('/settings/identifier'),
  createExternalRoute('/settings/team'),
  createExternalRoute('/settings/subscription-v2'),
  createExternalRoute('/webhooks'),
  createExternalRoute('/workflows'),
  createExternalRoute('/workflows/:id'),
  {
    enabled: true,
    path: '/flows',
    exact: true,
    render: routeProps => {
      return (
        <TemporaryLayout>
          <FlowsListingPage {...routeProps} />
        </TemporaryLayout>
      );
    },
  },
  {
    feature: NEXT_FLOW_SETTINGS,
    path: '/flows/:flowId/settings',
    exact: true,
    render: routeProps => {
      return (
        <TemporaryLayout>
          <FlowSettingsPage {...routeProps} />
        </TemporaryLayout>
      );
    },
  },
  {
    feature: PINS_BETA && PINS,
    path: `/pins/:pinId/settings`,
    render: routeProps => {
      return (
        <TemporaryLayout>
          <SettingsPage {...routeProps} />
        </TemporaryLayout>
      );
    },
  },
  {
    feature: PINS_BETA && PINS,
    path: '/pins/:pinId/analytics',
    render: routeProps => {
      return (
        <TemporaryLayout>
          <AnalyticsPage {...routeProps} />
        </TemporaryLayout>
      );
    },
  },
  {
    feature: PINS_BETA && PINS,
    path: '/pins',
    render: routeProps => {
      return (
        <TemporaryLayout>
          <PinsPage {...routeProps} />
        </TemporaryLayout>
      );
    },
  },
  {
    enabled: true,
    path: '/banners/:experienceId/settings',
    render: routeProps => {
      return (
        <TemporaryLayout>
          <BannerSettingsPage {...routeProps} />
        </TemporaryLayout>
      );
    },
  },
  {
    enabled: true,
    path: '/banners/:experienceId/analytics',
    render: routeProps => {
      return (
        <TemporaryLayout>
          <BannerAnalyticsPage {...routeProps} />
        </TemporaryLayout>
      );
    },
  },
  {
    enabled: true,
    path: '/banners',
    render: routeProps => {
      return (
        <TemporaryLayout>
          <BannersPage {...routeProps} />
        </TemporaryLayout>
      );
    },
  },
  {
    enabled: true,
    path: '/accounts',
    render: routeProps => {
      return <AccountsPage size="full" {...routeProps} />;
    },
  },
  {
    feature: MOBILE_FLOWS,
    path: '/mobile/flows/:experienceId/settings',
    render: routeProps => {
      return (
        <TemporaryLayout>
          <MobileSettingsPage {...routeProps} />
        </TemporaryLayout>
      );
    },
  },
  {
    feature: MOBILE_FLOWS,
    path: '/mobile/flows/:experienceId/analytics',
    render: routeProps => {
      return (
        <TemporaryLayout>
          <MobileAnalyticsPage {...routeProps} />
        </TemporaryLayout>
      );
    },
  },
  {
    enabled: true,
    path: '/signup_info',
    render: () => <SignupInfo />,
  },
  {
    enabled: true,
    path: '/usage',
    render: () => <UsageIntent />,
  },
  {
    enabled: true,
    path: '/welcome',
    render: () => <UserIntent />,
  },
  {
    enabled: true,
    path: '/invite-team-members',
    render: () => <InviteTeamMembers />,
  },
  {
    feature: LAUNCHPADS_V2,
    path: '/launchpads/:experienceId/settings',
    render: routeProps => {
      return (
        <TemporaryLayout>
          <LaunchpadSettingsPage {...routeProps} />
        </TemporaryLayout>
      );
    },
  },
  {
    feature: LAUNCHPADS_V2,
    path: '/launchpads',
    render: routeProps => {
      return (
        <TemporaryLayout>
          <LaunchpadsPage {...routeProps} />
        </TemporaryLayout>
      );
    },
  },
];

/**
 * Filter accessible routes based on feature flags
 *
 * @param {Route[]} registry - List of registered routes
 * @param {object<boolean>} features - Map of feature flags
 * @return {Route[]} Filtered list of routes
 */
export const filter = (registry = [], features = {}) =>
  registry.filter(({ feature, enabled }) => enabled ?? features[feature]);

/**
 * Top-level routes for studio next
 */
export function Routes({ features = {}, routes: registry = routes }) {
  const accessible = filter(registry, features);

  useListeners();

  return (
    <Switch>
      {accessible.map(({ path, ...rest }) => (
        <Route key={path} path={path} {...rest} />
      ))}
    </Switch>
  );
}

Routes.propTypes = {
  features: PropTypes.objectOf(PropTypes.bool),
  routes: PropTypes.shape({
    enabled: PropTypes.bool,
    exact: PropTypes.bool,
    feature: PropTypes.bool,
    path: PropTypes.string,
    render: PropTypes.func,
  }),
};

const mapStateToProps = state => ({
  features: selectFeatures(state),
});

export default connect(mapStateToProps)(Routes);
